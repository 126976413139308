<template>
  <RsModal
    class="modal-check-deposit__wrapper"
    :title="$t('certificatePage.depositCheckModal.title')"
    @dismiss="closeModal"
  >
    <template #header>
      <div class="flex justify-between items-end w-full md:px-4">
        <RsHeadline variant="h2">
          {{ $t('certificatePage.depositCheckModal.title') }}
        </RsHeadline>
        <IcCloseBlack class="cursor-pointer" width="24" @click="closeModal"/>
      </div>
    </template>

    <div class="max-h-[640px] flex flex-col space-y-4 py-4">
      <div class="relative flex justify-center items-center">
        <img src="/img/deposit-missing-qrcode.svg">

        <div class="absolute w-28 xs:w-32 mt-8">
          <RsQrCode v-if="pickupLink" :qr-value="pickupLink" />
        </div>
      </div>
      <p class="font-semibold text-xl">{{ $t('certificatePage.depositCheckModal.depositMissingTitle') }}</p>
      <p>{{ $t('certificatePage.depositCheckModal.depositMissingMessage') }}</p>
      <div class="!mt-8">
        <RsButton @click="_checkDepositMade">{{ $t('certificatePage.depositCheckModal.depositMadeButton') }}</RsButton>
      </div>
      <div v-if="hasError" class="text-red-500 text-xs">
        {{ $t('error.depositMissingError') }}
      </div>
    </div>
  </RsModal>
</template>

<script lang="ts" setup>
import { OnboardingPageType, type Booking } from '~/services/entities'
import useBookingRepository from '~/composables/repositories/useBookingRepository'
import { IcCloseBlack } from '~/components/common/icons'
import useLoading from '~/composables/useLoading'
import { useOnboardingFlow } from '~/composables/usecases';
import RsQrCode from '~/components/core/RsQrCode.vue'

const props = defineProps<{
  booking: Booking,
  pickupLink: {
    type: String,
    required: true
  }
}>()
const { startLoading, stopLoading } = useLoading()
const { booking, pickupLink } = toRefs(props)

const hasError = ref(false)

const emit = defineEmits(['commit'])
const closeModal = () => emit('commit')

const { checkDepositMade } = useBookingRepository()

const {
  trackStartFinalChecks,
} = useOnboardingFlow()

const route = useRoute()
const router = useRouter()

const _checkDepositMade = async () => {
  try {
    startLoading()
    hasError.value = false

    const response = await checkDepositMade(booking.value?.bookingNumber)
    if (response.data && response.data.depositMade) {
      router.push({
        path: route.path,
        query: {
          ...route.query,
          page: OnboardingPageType.FINAL_CHECK,
        }
      })
      trackStartFinalChecks()
      closeModal()
    } else {
      hasError.value = true
    }
  } catch {
    hasError.value = true
  } finally {
    stopLoading()
  }
}
</script>

<style lang="postcss" scoped>
.modal-check-deposit__wrapper :deep(.modal__window) {
  @apply max-h-[640px] rounded-lg h-auto;
}
.modal-check-deposit__wrapper :deep(.modal__body) {
  @apply max-h-full;
}
.modal-check-deposit__wrapper :deep(.modal__header) {
  @apply border-b-0;
}
.modal-check-deposit__wrapper :deep(.modal__footer) {
  @apply border-t-0;
}
.modal-check-deposit__wrapper {
  @apply !p-4 items-center;
}
</style>